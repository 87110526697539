// Documentation for config options: https://docs.podlove.org/podlove-web-player/meta-data.html

const PLAYER_VERSION = 5;

const durationToISO8601 = (duration, fallback = '00:00:00.000') => {
  if (!duration) {
    return fallback;
  }

  // Date.toISOString returns a date as 1970-01-01T00:00:00.000Z.
  // We need 12 chars starting from 11th position to get the correct format ([hh]:[mm]:[ss].[sss])
  return new Date(duration * 1000).toISOString().substr(11, 12);
};

const titleFor = (episode = {}) => {
  const title = [];
  if (episode.episodeNumber) {
    title.push(`#${episode.episodeNumber}`);
  }
  title.push(episode.title);

  if (episode.explicit) {
    title.push('[explicit]');
  }
  return title.join(' ');
};

const fontConfig = ({ fontFamily = 'Open Sans', weight = 400 }) => ({
  name: fontFamily,
  family: [fontFamily, 'sans-serif'],
  weight,
});

export const playerConfig = ({ podcast }) => {
  const { brandSettings, locale } = podcast || {};
  const {
    primary_color: primaryColor,
    primary_text_color: primaryTextColor,
    font_family: fontFamily,
  } = brandSettings || {};

  return {
    version: PLAYER_VERSION,
    activeTab: null,
    runtime: {
      language: locale,
    },
    theme: {
      // https://docs.podlove.org/podlove-web-player/theme.html
      tokens: {
        brand: primaryTextColor,
        brandDark: primaryTextColor,
        brandDarkest: primaryTextColor,
        brandLightest: primaryColor,
        shadeDark: '#807E7C',
        shadeBase: '#807E7C',
        contrast: primaryTextColor,
        alt: primaryColor,
      },
      fonts: {
        ci: fontConfig({ fontFamily, weight: 700 }),
        regular: fontConfig({ fontFamily, weight: 400 }),
        bold: fontConfig({ fontFamily, weight: 700 }),
      },
    },
    share: {
      channels: ['facebook', 'twitter', 'mail', 'link'],
      sharePlaytime: true,
    },
    features: {
      persistTab: false,
      persistPlaystate: false,
    },
  };
};

export const episodeConfig = ({ podcast = {}, episode = {} }) => {
  const { media = {} } = episode || {};

  return {
    version: PLAYER_VERSION,
    runtime: {
      language: podcast.locale,
    },
    show: {
      title: podcast.title || '',
      summary: podcast.description || '',
      poster: podcast.thumbnailUrl,
      link: podcast.url,
    },
    title: titleFor(episode),
    summary: episode.description || '',
    publicationDate: episode.publicationDate,
    poster: episode.thumbnailUrl,
    duration: durationToISO8601(episode.duration),
    link: episode.url,
    audio: [
      {
        url: media.url,
        size: media.size || 0,
        title: episode.title || '',
        mimeType: media.type,
      },
    ],
    chapters: episode.chapters,
  };
};
