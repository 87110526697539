import { playerConfig, episodeConfig } from './config';

class Player {
  constructor(element, manager) {
    this.container = element;
    this.manager = manager;
    this.playerStore = null;
    this.isLoaded = false;
    this.isPlaying = false;
    this.initBindings();
    this.render();
  }

  initBindings() {
    this.configId = this.container.getAttribute('data-episode-config');
    const configContainer = document.getElementById(this.configId);

    this.config = { episode: { media: {} }, podcast: {} };

    if (!configContainer || !configContainer.textContent) {
      return;
    }

    try {
      this.config = JSON.parse(configContainer.textContent);
    } catch (err) {
      // no-op
    }
  }

  stateListener() {
    if (!this.manager) {
      return;
    }
    const prevPlayingState = this.isPlaying;
    this.isPlaying = this.playerStore.getState().driver.playing;

    if (prevPlayingState !== this.isPlaying && this.isPlaying) {
      this.manager.playOnly(this.configId);
    }
  }

  setPlayerStore(store) {
    this.playerStore = store;
    store.subscribe(this.stateListener.bind(this));
  }

  setMedia(data = {}) {
    const mediaAttributes = ['url', 'type', 'size'];
    const episodeAttributes = [
      'title',
      'episodeNumber',
      'explicit',
      'thumbnailUrl',
      'duration',
      'chapters',
    ];

    const media = {};
    const episode = {};

    if (data.url) {
      mediaAttributes.forEach((key) => {
        media[key] = data[key];
      });
      episode.media = { ...this.config.episode.audio, ...media };
    }

    episodeAttributes.forEach((key) => {
      if (typeof data[key] !== 'undefined') {
        episode[key] = data[key];
      }
    });
    this.config.episode = { ...this.config.episode, ...episode };
    this.render();
  }

  isModernBrowser() {
    return 'fetch' in window && 'assign' in Object;
  }

  fallbackPlayer() {
    const audioElement = document.createElement('audio');
    audioElement.controls = 'controls';
    audioElement.preload = 'none';
    audioElement.src = this.config.episode.media.url;
    audioElement.type = this.config.episode.media.type;

    this.container.appendChild(audioElement);
  }

  initPlayer() {
    if (this.isModernBrowser()) {
      window
        .podlovePlayer(this.container, episodeConfig(this.config), playerConfig(this.config))
        .then(this.setPlayerStore.bind(this));
    } else {
      this.fallbackPlayer();
    }

    this.isLoaded = true;

    return this.isLoaded;
  }

  render() {
    if (!this.isLoaded && this.config.episode.media.url) {
      return this.initPlayer();
    }

    if (!this.playerStore) {
      return null;
    }

    return this.playerStore.dispatch({
      type: 'PLAYER_INIT',
      payload: { ...playerConfig(this.config), ...episodeConfig(this.config) },
    });
  }

  pause() {
    if (!this.playerStore) {
      return;
    }
    if (this.isPlaying) {
      this.playerStore.dispatch({ type: 'PLAYER_REQUEST_PAUSE' });
    }
  }
}

export default Player;
